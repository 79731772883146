import React from "react"
import { Link } from "gatsby"
import styled from "styled-components"
import Collapsible from "react-collapsible"
import Layout from "../components/layout"
import SEO from "../components/seo"
import CollapsibleItem from "../components/collapsibleItemFaq"

const FaqContent = styled.div`
  .Collapsible__trigger {
    display: block;
    cursor: pointer;
    color: var(--main-black);
    font-size: 1.414rem;
    line-height: 1.6968rem;
    padding: 1rem 2rem;
    margin: 1.5rem 0;
    border-left: 2px solid var(--very-light-blue);
    svg {
      transition: transform 300ms;
    }
    &.is-open svg {
      transform: rotateZ(90deg);
    }
  }
`

const faq = () => {
  return (
    <Layout>
      <SEO
        title="Frequently Asked Questions in PES 2021"
        description="Here are the answers to some of the most popular questions asked regarding PES 2021 myClub."
      />
      <div className="container">
        <h1>Frequently Asked Questions in PES 2021</h1>
        <ul className="breadcrumb" style={{ paddingTop: "1rem" }}>
          <li>
            <Link to="/">Home</Link>
          </li>
          <li>Frequently Asked Questions</li>
        </ul>
        <FaqContent>
          <p>
            In this page you can find my answers to some of the most popular
            issues people have with PES 2021.
          </p>
          <p>
            I made this list based on e-mails I get, and questions friends ask
            me.
          </p>
          <Collapsible
            trigger={
              <CollapsibleItem
                text="I have several versions of Player X. Why can't I trade him for
            another player?"
              />
            }
            contentContainerTagName="div"
            open
          >
            <p>
              Because you need to have 3 copies of the exact version of the
              player. If, for example, you have the default Gold Ball version of
              the Player and you spin him twice as a featured Player of the
              Week, you won't be able to trade those 3 copies, since they don't
              match.
            </p>
            <p>
              Keep in mind that Ball Type is not enough. Let's suppose you kept
              a Black Ball Player of the Week from Week 3 and you spin him again
              twice when he was once again featured on Week 24. Even though they
              are all Black Balls, they are not the same version, so you won't
              be allowed to trade him.
            </p>
            <p>
              You would need to get that featured Player of the Week 3 times
              (the exact version of that week), or the Gold Ball version (by
              spinning agents, since you can't scout a player you already have
              in your club).
            </p>
          </Collapsible>
          <Collapsible
            trigger={
              <CollapsibleItem
                text="Will I lose my players if I don't have the GP to renew their
            contracts after their last game? "
              />
            }
            contentContainerTagName="div"
          >
            <p>
              No, you never lose your players. As long as you don't release them
              from the club or convert them into trainers, they will always
              remain available in your club.
            </p>
            <p>The only exception is loaned players.</p>
          </Collapsible>
          <Collapsible
            trigger={
              <CollapsibleItem text="If I buy the new version of PES, will my myClub team from the previous year be available? What about my Legends?" />
            }
            contentContainerTagName="div"
          >
            <p>
              You start a new team from scratch every year. You can't import
              teams or players from previous years.
            </p>
          </Collapsible>
          <Collapsible
            trigger={
              <CollapsibleItem text="How can I see how many Appearances, Goals or Assists my player has?" />
            }
            contentContainerTagName="div"
          >
            <p>
              In the Club House, go to "Squad Management". Select a player,
              press "X" to highlight him, and use the "Compare" feature by
              pressing Triangle.
            </p>
            <p>
              If you go to Page 2 with R1, you will see Appearances, Goals,
              Assists, Avg Ratings and other useful info.
            </p>
          </Collapsible>
          <Collapsible
            trigger={
              <CollapsibleItem text="How can I train a player with duplicates if I can't scout him?" />
            }
            contentContainerTagName="div"
          >
            <p>
              You can't scout a player you already have in your club. Your best
              bet is to scout a player, use him for 10 games and convert him to
              a trainer, and then scout him again. Do not touch any of those
              trainers you are piling up.
            </p>
            <p>
              Once you have plenty of trainers this way, scout that player one
              final time and apply all those trainers to him.
            </p>
          </Collapsible>
          <Collapsible
            trigger={
              <CollapsibleItem text="Can you sell players by auctioning them?" />
            }
            contentContainerTagName="div"
          >
            <p>No, that is not possible in PES 2021.</p>
          </Collapsible>
          <Collapsible
            trigger={
              <CollapsibleItem text="How do I get scout X after playing a match?" />
            }
            contentContainerTagName="div"
          >
            <p>
              You get a random scout after each match, so it can take many
              matches until you get the scout you want. You should go to the{" "}
              <Link to="/signing-new-players-scout-combinations/">
                Auctions House
              </Link>{" "}
              to get that specific scout.
            </p>
          </Collapsible>
          <Collapsible
            trigger={
              <CollapsibleItem text="I never got a 5 Star Scout after completing a match. Why?" />
            }
            contentContainerTagName="div"
          >
            <p>
              Assuming Konami is not running a specific campaign, the only way
              to get a 5 star scout is by participating (and winning) an
              auction. Have a look at{" "}
              <Link to="/signing-new-players-scout-combinations/">
                this section
              </Link>
              .
            </p>
          </Collapsible>
          <Collapsible
            trigger={
              <CollapsibleItem text="How much money do I need to spend in this game to have a great team?" />
            }
            contentContainerTagName="div"
          >
            <p>
              You don't need to spend any money. You can build a strong team in
              PES in just a couple of weeks.
            </p>
            <p>
              Coins will definitely speed up the process, but you don't need to
              spend a dime. First, make sure you complete all the achievements
              that reward you with coins (visit "myClub Records" in the CLUB
              HOUSE screen). That will be more than enough to buy a good manager
              and some superstars.
            </p>
            <p>
              Konami sometimes gives you more coins, as a way to celebrate a
              special occasion (like the launch of PES Mobile, or Christmas and
              New Year). So pay attention to those announcements.
            </p>
            <p>
              Lastly, make sure you login every day. That way, you will get 100
              coins per week. That's a new superstar every week.
            </p>
            <p>This is more than enough to have a good team after a while.</p>
          </Collapsible>
          <Collapsible
            trigger={
              <CollapsibleItem text="Why is there an option to reset a box?" />
            }
            contentContainerTagName="div"
          >
            <p>Box resets can happen for several reasons:</p>
            <ul>
              <li>
                People have already opened the whole box and want to open again
                (to get duplicates).
              </li>
              <li>
                People want duplicates to train their players with them, and
                resetting it early saves GP/coins.
              </li>
              <li>
                People get a black ball and decide to reset the box thinking
                that the next black ball can come faster (or that the next is
                the one they're looking for).
              </li>
            </ul>
            <p>
              Personally, I never felt the need to reset the box, but the option
              is there.
            </p>
          </Collapsible>
          <Collapsible
            trigger={
              <CollapsibleItem text="How many players should I have per positions (to avoid the fluctuations in form)?" />
            }
            contentContainerTagName="div"
          >
            <p>
              I usually keep around 100 players in my team, because I play many
              online friendlies with my buddies and I like to mix it up.
            </p>
            <p>
              I would say that 3-4 good players per position are good to have,
              because of the weekly form updates - I change my team every week
              according to their good performances in real life.
            </p>
          </Collapsible>
          <Collapsible
            trigger={
              <CollapsibleItem text="I have accidently converted a player into a trainer. Can I go back?" />
            }
            contentContainerTagName="div"
          >
            <p>
              Once you convert a player into a trainer, you can't reverse this
              decision. Make sure you lock your players to prevent this from
              happening.
            </p>
          </Collapsible>
          <Collapsible
            trigger={
              <CollapsibleItem text="How can I get Position, Skill or EXP trainers?" />
            }
            contentContainerTagName="div"
          >
            <p>
              You can purchase some Skills or Position trainers for 500 points
              each if you have some{" "}
              <Link to="/coins-gp/">eFootball points</Link> to spare.
            </p>
            <p>
              Otherwise, you will need to wait until they are offering those
              tokens. Keep an eye on weekly challenges or weeks when Konami
              awards them by logging in.
            </p>
          </Collapsible>
          <Collapsible
            trigger={
              <CollapsibleItem text="How can I reset my team on myClub?" />
            }
            contentContainerTagName="div"
          >
            <p>
              If you want to start from scratch, you can go to “Extras” (last
              screen) and choose “Reset team”. I have never done it, but I
              believe you won't get any coins again, and will have the same GP
              as before you click on that button.
            </p>
          </Collapsible>
          <Collapsible
            trigger={
              <CollapsibleItem text="I won an auction. Where is my scout?" />
            }
            contentContainerTagName="div"
          >
            <p>
              If you won the auction, the available scout should be available in
              your inbox. You need to redeem it first, before you can use it.
            </p>
          </Collapsible>
          <Collapsible
            trigger={
              <CollapsibleItem text="After the game, I don't have the option to renew players with my Contract Renewal Tickets. Why?" />
            }
            contentContainerTagName="div"
          >
            <p>
              When a player’s contract runs out, after a game and after the
              manager performance screen, you will only have a chance to renew
              them with GP or coins.
            </p>
            <p>
              To use your contract renewal tickets, you will need to choose “No”
              on that screen (you won’t lose the player), and then go to Squad
              Management and press square (on PS4) on the desired player.
            </p>
            <p>
              You will then have the option to use your contract renewal
              tickets.
            </p>
          </Collapsible>
          <Collapsible
            trigger={
              <CollapsibleItem text="I used a 4 star scout but did not get a Gold Ball player. Why?" />
            }
            contentContainerTagName="div"
          >
            <p>
              Using a 4* scout on its own does not guarantee you a Gold Ball
              player. You need to combine scouts to narrow down the possible
              options and get the player you want.{" "}
            </p>
            <p>
              If you want a Gold Ball Player, only a combination of 4* scouts
              will give you the player with 100% certainty.{" "}
              <Link to="/signing-new-players-scout-combinations/">
                Please read this section carefully.
              </Link>
            </p>
          </Collapsible>
          <Collapsible
            trigger={
              <CollapsibleItem text="Where is Training mode in PES 2021?" />
            }
            contentContainerTagName="div"
          >
            <p>
              It used to be available inside myClub in previous editions, but in
              PES 2020 that changed. You can now find it in the main PES screen,
              under Kick Off (last option, scroll down).
            </p>
          </Collapsible>
        </FaqContent>
      </div>
    </Layout>
  )
}

export default faq
